<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import { useUserStore } from '~/stores/user'

    type Props = {
        inNavbar?: boolean
    }

    type Emit = {
        (event: 'toggle-tips' | 'toggle-help' | 'close'): void
    }

    type Item = {
        text: string
        iconComponent?: ReturnType<typeof defineComponent>
        active?: boolean
        divider?: boolean
        help?: boolean
        tips?: boolean
        action?: AnyFn
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const userStore = useUserStore()

    const route = useRoute()

    const items = computed<Item[]>(() => {
        const items: Item[] = [
            {
                iconComponent: resolveComponent('AppIconProjectAdd'),
                text: 'create-project',
                active: route.name === 'p-pid-setting-project',
                action: () => navigateTo({ name: 'p-pid-setting-project-create' }) && emit('close'),
            },
            {
                iconComponent: resolveComponent('AppIconCog'),
                text: 'set-up-account',
                active: route.name === 'p-pid-setting-account',
                action: () => navigateTo({ name: 'p-pid-setting-account' }) && emit('close'),
            },
            {
                divider: true,
                text: 'divider-1',
            },
        ]

        if (props.inNavbar && userStore.isOperatorEnabled && userStore.currentOperator.onboarding) {
            items.push({
                tips: true,
                text: 'tips',
            })
        }

        items.push(...[
            {
                help: true,
                text: 'help',
            },
            {
                iconComponent: resolveComponent('AppIconLogout'),
                text: 'logout',
                action: () => {
                    userStore.logout()

                    emit('close')
                },
            },
        ])

        if (userStore.isOperatorEnabled && userStore.isAdministrator) {
            items.unshift({
                iconComponent: resolveComponent('AppIconUserAdd'),
                text: 'add-operator',
                active: route.name === 'p-pid-setting-operator',
                action: () => navigateTo({ name: 'p-pid-setting-operator-create' }) && emit('close'),
            })
        }

        return items
    })
</script>

<template>
    <div class="flex flex-col gap-1 px-4">
        <template
            v-for="item in items"
            :key="item.text"
        >
            <AppDivider
                v-if="item.divider"
                class="!my-1"
            />

            <TheSidebarActionAccountNavigationHelp
                v-else-if="item.help"
                v-slot="{ active, toggle }"
                @close="emit('close')"
            >
                <TheSidebarActionSelectItem
                    key="help"
                    :active="active"
                    @click="props.inNavbar ? emit('toggle-help') : toggle()"
                >
                    <AppIconQuestion size="20" />

                    <div class="ml-4">
                        {{ $t('help') }}
                    </div>

                    <AppIconChevronRight
                        size="20"
                        class="ml-auto"
                        :rotate="active"
                    />
                </TheSidebarActionSelectItem>
            </TheSidebarActionAccountNavigationHelp>

            <TheSidebarActionSelectItem
                v-else-if="item.tips"
                key="tips"
                @click="emit('toggle-tips')"
            >
                <TheSidebarActionTipsActivator :in-navbar="props.inNavbar" />

                <div class="ml-4">
                    {{ $t('tips') }}
                </div>

                <AppIconChevronRight
                    size="20"
                    class="ml-auto"
                />
            </TheSidebarActionSelectItem>

            <TheSidebarActionSelectItem
                v-else
                :active="item.active"
                @click="item.action()"
            >
                <component
                    :is="item.iconComponent"
                    size="20"
                />

                <div class="ml-4">
                    {{ $t(item.text) }}
                </div>
            </TheSidebarActionSelectItem>
        </template>
    </div>
</template>
