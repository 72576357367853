<script
    lang="ts"
    setup
>
    import type { OperatorSchedule } from '~/ts/types/operator'

    type Props = {
        operatorSchedule: OperatorSchedule
    }

    type Option = {
        day: string
        from: string
        to: string
    }

    const props = defineProps<Props>()

    const workSchedule = computed<Option[]>(() => {
        return DATE_DAYS_SHORT.reduce<Option[]>((result, day) => {
            if (props.operatorSchedule[day + '_enabled'] && props.operatorSchedule[day + '_from']) {
                result.push({
                    day,
                    from: props.operatorSchedule[day + '_from'].substring(0, 5),
                    to: (props.operatorSchedule[day + '_to'] || '-').substring(0, 5),
                })
            }

            return result
        }, [])
    })
</script>

<template>
    <div class="flex flex-col gap-1 w-full px-2">
        <div class="text-base font-medium">
            {{ $t('my-work-schedule') }}
        </div>

        <AppHint class="">
            {{ $t('my-work-schedule-hint') }}
        </AppHint>

        <div class="flex flex-col">
            <div
                v-for="item in workSchedule"
                :key="item.day"
                class="flex items-center gap-3 text-[14px] h-[34px]"
            >
                <div class="w-[24px] font-medium">
                    {{ $t(item.day) }}
                </div>

                <div class="flex items-center gap-1.5">
                    <div class="!w-[44px]">
                        {{ item.from }}
                    </div>

                    <AppHint>
                        –
                    </AppHint>

                    <div class="">
                        {{ item.to }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
