<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'close'): void
    }

    const emit = defineEmits<Emit>()

    const popoverRef = ref<ReturnType<typeof defineComponent>>()

    const close = (): void => {
        popoverRef.value.close()

        emit('close')
    }
</script>

<template>
    <TheSidebarPopover
        ref="popoverRef"
        popover-class="min-w-[272px] left-[calc(100%+32px)] !bottom-0"
    >
        <template #activator="{ active, toggle }">
            <slot
                :active="active"
                :toggle="toggle"
            />
        </template>

        <TheSidebarActionAccountNavigationHelpContent @close="close" />
    </TheSidebarPopover>
</template>
