<script
    lang="ts"
    setup
>
    import type { Project } from '~/ts/types/project'
    import { useAppStore } from '~/stores/app'
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'

    type Props = {
        containerClass?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        containerClass: '',
    })

    type Emit = {
        (event: 'close'): void
    }

    const emit = defineEmits<Emit>()

    const containerRef = ref<ReturnType<typeof defineComponent>>()

    const appStore = useAppStore()
    const userStore = useUserStore()
    const chatStore = useChatStore()

    const showProjectLabels = computed<boolean>(() => !!(userStore.myProjects.length && userStore.guestProjects.length))
    const containerClass = computed<string>(() => {
        return `
            overflow-y-auto
            flex
            flex-col
            gap-1
            max-h-[240px]
            [&>*:last-child]:mb-2
            vanishing-specific-target
            ${ props.containerClass }
        `
    })

    const selectProject = (project: Project) => {
        emit('close')

        if (userStore.currentProject.id === project.id) {
            return
        }

        appStore.showLoader = true

        useAppCookie('project').value = String(project.id)

        useNotify().push({
            type: 'info',
            text: useLang().t('project-changed-to') + ' ' + project.name,
            afterPageReload: true,
        })

        location.href = useRouter().resolve({
            name: 'p-pid-chat',
            params: { pid: project.id },
        }).fullPath
    }

    onMounted(() => {
        setTimeout(() => {
            containerRef.value
                .querySelector(`[data-id="${ userStore.currentProject.id }"]`)
                .scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth',
                })
        }, 50)
    })
</script>

<template>
    <AppScrollGradientContainer
        v-slot="{ slotRef }"
        class="mt-4 -mb-1 px-4"
    >
        <div
            :ref="el => { containerRef = el as any; slotRef(el) }"
            :class="containerClass"
        >
            <AppHint
                v-if="showProjectLabels"
                key="my-projects"
                class="my-2 uppercase !text-[10px]"
            >
                {{ $t('my-projects') }}
            </AppHint>

            <!-- eslint-disable @stylistic/max-len -->
            <TheSidebarActionAccountProjectsItem
                v-for="project in userStore.myProjects"
                :key="`project-id-` + project.id"
                :project="project"
                :current-project="userStore.currentProject"
                :show-notification-badge="!!chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId[project.id]"
                @click="selectProject(project)"
            />
            <!-- eslint-enable @stylistic/max-len -->

            <AppHint
                v-if="showProjectLabels"
                key="guest-projects"
                class="my-2 uppercase !text-[10px]"
            >
                {{ $t('invited-projects') }}
            </AppHint>

            <!-- eslint-disable @stylistic/max-len -->
            <TheSidebarActionAccountProjectsItem
                v-for="project in userStore.guestProjects"
                :key="`project-id-` + project.id"
                :project="project"
                :current-project="userStore.currentProject"
                :show-notification-badge="!!chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId[project.id]"
                @click="selectProject(project)"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </div>
    </AppScrollGradientContainer>
</template>
