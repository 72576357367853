<script
    lang="ts"
    setup
>
    import type { OperatorOfflineStatus } from '~/ts/types/operator'

    type Props = {
        inputModel: OperatorOfflineStatus
        isOperatorOnline: boolean
        popoverActive: boolean
        pending: boolean
        inNavbar?: boolean
    }

    type Emit = {
        (event: 'toggle-input-model'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()
</script>

<template>
    <AppTooltip
        :direction="props.inNavbar ? 'left' : 'right'"
        no-wrap
        offset-y="-6"
        tail-offset-y="-3"
    >
        <template #activator="{ open: openTooltip, close: closeTooltip }">
            <label
                class="flex items-center justify-center min-h-[50px] cursor-pointer"
                @mouseenter.passive="!props.popoverActive && openTooltip()"
                @mouseleave.passive="closeTooltip()"
            >
                <AppFormFieldToggle
                    key="input"
                    name="work-status"
                    :model-value="props.inputModel"
                    :input-value-true="null"
                    :prevent-value-change="props.pending"
                    :class="{ 'ml-2': props.inputModel }"
                    @update:model-value="emit('toggle-input-model'); closeTooltip()"
                />

                <AppIconChevronDown
                    v-if="props.isOperatorOnline"
                    key="icon"
                    size="18"
                    :rotate="props.popoverActive"
                    class="overflow-hidden ml-1"
                />
            </label>
        </template>

        <slot />
    </AppTooltip>
</template>
